import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const getData = graphql`
  {
    contentfulPage(slug: { eq: "about" }) {
      body {
        childMarkdownRemark {
          html
        }
      }
      pageTitle
      slug
    }
  }
`;

function AboutPage() {
  const {
    contentfulPage: {
      body: { childMarkdownRemark: html },
      pageTitle,
    },
  } = useStaticQuery(getData);
  console.log({ html, pageTitle });
  return (
    <Layout>
      <SEO
        keywords={[
          `livestock`,
          `breeding`,
          `grazing`,
          `feedlot`,
          `lambs`,
          `cattle`,
          `agriculture`,
          `jobs`,
        ]}
        title={pageTitle}
      />
      <section>
        <div className="flex flex-wrap items-center justify-between max-w-4xl p-4 mx-auto md:p-8">
          <div>
            <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              {pageTitle}
            </h1>
            <div
              dangerouslySetInnerHTML={{
                __html: html.html,
              }}
            />
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default AboutPage;
